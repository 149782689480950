import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AgmCoreModule} from '@agm/core';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBV2d5s6T8DEsL_wA9_oW-EfnHCLamAcJI'
            /* apiKey is required, unless you are a
            premium customer, in which case you can
            use clientId
            */
        })
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}

