import { NgModule } from '@angular/core';
import {Routes, RouterModule, PreloadAllModules, ExtraOptions} from '@angular/router';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'main',
    },
    { path: 'main',
        loadChildren: () => import('./main/main.module').then(m => m.MainModule)
    },
    {path: '**', redirectTo: 'main', pathMatch: 'full'}
];


const extraOptions: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, extraOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
